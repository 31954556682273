import React from "react"

import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"

import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import styled from "styled-components"

const Container = styled.div`
  max-width: 300px;
`

const PostLink = ({ post }) => (
  <Link to={`/${post.frontmatter.path}`} className="box">
    <div className="columns">
      <Container className="column">
        <Img
          fluid={post.frontmatter.imagen.childImageSharp.fluid}
          alt={post.frontmatter.title}
        />
      </Container>
      <div className="column">
        <div className="content">
          <div className="tags">
            {/* {post.frontmatter.paises.map((pais, index) => (
              <span className="tag is-primary" key={index}>
                {pais}
              </span>
            ))} */}
            {post.frontmatter.tags.map((tag, index) => (
              <span className="tag is-primary" key={index}>
                {tag}
              </span>
            ))}
          </div>
          <h2 className="title is-4">{post.frontmatter.title}</h2>
          <h3 className="subtitle is-6">({post.frontmatter.date})</h3>
          <div className="is-hidden-mobile">{post.excerpt}</div>
        </div>
      </div>
    </div>
  </Link>
)

export default ({ data }) => (
  <Layout>
    <PageTitle title="Blog" />
    <div className="section">
      <div className="container">
        {data.allMarkdownRemark.edges.map((edge, index) => (
          <PostLink key={edge.node.id} post={edge.node} />
        ))}
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "D MMMM, YYYY", locale: "es")
            path
            tags
            title
            imagen {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
